var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticStyle:{"z-index":"250 !important"},attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"color":_vm.$primary,"dark":""}},on),[_vm._v(_vm._s(_vm.ModalNewText))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.DialogTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":true}},[_c('v-row',_vm._l((_vm.FormFields),function(item,idx){return _c('v-col',{key:idx,attrs:{"cols":item.size.cols,"sm":item.size.sm,"md":item.size.md}},[(typeof item.label != 'undefined')?_c('label',{staticClass:"label-modal"},[_vm._v(_vm._s(_vm.$t(`fields.${item.label}`)))]):_vm._e(),(item.type == 'subheader')?_c('v-subheader',[_vm._v(_vm._s(item.title))]):_vm._e(),(item.type == 'text')?_c('v-text-field',{attrs:{"autocomplete":"off","rules":item.rules.map(el => {
                    return _vm.RULES[el]
                  }),"color":_vm.$primary,"disabled":(_vm.Store == 'at' || _vm.Store == 'obra') && item.name == 'codigo'},model:{value:(_vm.MyObject[item.name]),callback:function ($$v) {_vm.$set(_vm.MyObject, item.name, $$v)},expression:"MyObject[item.name]"}}):_vm._e(),(item.type == 'textarea')?_c('v-textarea',{attrs:{"autocomplete":"off","rows":item.rows,"auto-grow":true,"rules":item.rules.map(el => {
                    return _vm.RULES[el]
                  }),"color":_vm.$primary},model:{value:(_vm.MyObject[item.name]),callback:function ($$v) {_vm.$set(_vm.MyObject, item.name, $$v)},expression:"MyObject[item.name]"}}):_vm._e(),(item.type == 'array')?_c('v-autocomplete',{attrs:{"clearable":"","autocomplete":"off","items":_vm.AutoCompleteValues(item.store),"disabled":_vm.$_ISDISABLED(
                    item.disabled,
                    _vm.getSession.user.id_tipo_utilizador
                  ) || (_vm.Store == 'at' && item.store == 'cliente' && _vm.getIsOrcamentosLoading),"color":_vm.$primary,"rules":item.rules.map(el => {
                    return _vm.RULES[el]
                  }),"multiple":item.selectMultiple,"item-text":item.text,"item-value":item.value,"loading":item.store == 'cliente' && _vm.IsClienteLoading},on:{"click:clear":function($event){return _vm.clearAuto(item.store)},"change":function($event){return _vm.changeAuto(item.store)}},model:{value:(_vm.MyObject[item.name]),callback:function ($$v) {_vm.$set(_vm.MyObject, item.name, $$v)},expression:"MyObject[item.name]"}}):_vm._e(),(item.type == 'password' && _vm.MyIndex == -1)?_c('PASSWORD',{attrs:{"MyObject":_vm.MyObject}}):_vm._e(),(item.type == 'orcamento')?_c('ORCAMENTO',{attrs:{"MyObject":_vm.MyObject}}):_vm._e(),(item.type == 'data')?_c('DATA',{attrs:{"MyObject":_vm.MyObject,"Field":item.name,"Title":item.title,"RULES":item.rules.map(el => {
                    return _vm.RULES[el]
                  })}}):_vm._e(),(item.type == 'time')?_c('TIME',{attrs:{"MyObject":_vm.MyObject,"Field":item.name,"Title":item.title,"RULES":item.rules.map(el => {
                    return _vm.RULES[el]
                  })}}):_vm._e(),(item.type == 'dcl')?_c('DCL',{attrs:{"MyObject":_vm.MyObject}}):_vm._e(),(item.type == 'image')?_c('IMAGEM',{attrs:{"MyObject":_vm.MyObject,"Field":item.name}}):_vm._e()],1)}),1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":_vm.$primary,"text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("actions.cancel"))+" ")]),_c('v-btn',{attrs:{"color":_vm.$primary,"text":"","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("actions.confirm"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }