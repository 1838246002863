<template>
  <v-dialog style="z-index: 250 !important;" v-model="dialog" max-width="700px">
    <template v-slot:activator="{ on }">
      <v-btn :color="$primary" dark v-on="on">{{ ModalNewText }}</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ DialogTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" :lazy-validation="true">
            <v-row>
              <v-col
                v-for="(item, idx) in FormFields"
                :key="idx"
                :cols="item.size.cols"
                :sm="item.size.sm"
                :md="item.size.md"
              >
                <label
                  v-if="typeof item.label != 'undefined'"
                  class="label-modal"
                >{{ $t(`fields.${item.label}`) }}</label>

                <v-subheader v-if="item.type == 'subheader'">{{ item.title }}</v-subheader>




                <v-text-field
                  autocomplete="off"
                  v-if="item.type == 'text'"
                  v-model="MyObject[item.name]"
                  :rules="
                    item.rules.map(el => {
                      return RULES[el]
                    })
                  "
                  :color="$primary"
                  :disabled="
                    (Store == 'at' || Store == 'obra') && item.name == 'codigo'
                  "
                ></v-text-field>

                <v-textarea
                  autocomplete="off"
                  v-if="item.type == 'textarea'"
                  v-model="MyObject[item.name]"
                  :rows="item.rows"
                  :auto-grow="true"
                  :rules="
                    item.rules.map(el => {
                      return RULES[el]
                    })
                  "
                  :color="$primary"
                ></v-textarea>
                <v-autocomplete
                  @click:clear="clearAuto(item.store)"
                  clearable
                  autocomplete="off"
                  v-if="item.type == 'array'"
                  :items="AutoCompleteValues(item.store)"
                  @change="changeAuto(item.store)"
                  :disabled="
                    $_ISDISABLED(
                      item.disabled,
                      getSession.user.id_tipo_utilizador
                    ) || (Store == 'at' && item.store == 'cliente' && getIsOrcamentosLoading)
                  "
                  :color="$primary"
                  v-model="MyObject[item.name]"
                  :rules="
                    item.rules.map(el => {
                      return RULES[el]
                    })
                  "
                  :multiple="item.selectMultiple"
                  :item-text="item.text"
                  :item-value="item.value"
                  :loading="item.store == 'cliente' && IsClienteLoading"
                ></v-autocomplete>

                <PASSWORD v-if="item.type == 'password' && MyIndex == -1" :MyObject="MyObject" />

                <ORCAMENTO v-if="item.type == 'orcamento'" :MyObject="MyObject" />

                <DATA
                  v-if="item.type == 'data'"
                  :MyObject="MyObject"
                  :Field="item.name"
                  :Title="item.title"
                  :RULES="
                    item.rules.map(el => {
                      return RULES[el]
                    })
                  "
                />

                <TIME
                  v-if="item.type == 'time'"
                  :MyObject="MyObject"
                  :Field="item.name"
                  :Title="item.title"
                  :RULES="
                    item.rules.map(el => {
                      return RULES[el]
                    })
                  "
                />

                <DCL v-if="item.type == 'dcl'" :MyObject="MyObject" />

                <IMAGEM v-if="item.type == 'image'" :MyObject="MyObject" :Field="item.name" />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="$primary" text @click="close">
          {{
          $t("actions.cancel")
          }}
        </v-btn>
        <v-btn :color="$primary" text :loading="loading" @click="save">
          {{
          $t("actions.confirm")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CRUD from "@/mixins/_CRUD"
import RULES from "@/mixins/_RULES"
export default {
  components: {
    PASSWORD: () => import("@/components/inputs/password"),
    DATA: () => import("@/components/inputs/Data"),
    TIME: () => import("@/components/inputs/Time"),
    IMAGEM: () => import("@/components/inputs/Imagem"),
    ORCAMENTO: () => import("@/components/selects/Orcamento"),
    DCL: () => import("@/components/selects/DCL"),
  },
  mixins: [CRUD, RULES],
  props: {
    FormFields: Array,
    DialogTitle: String,
    ModalNewText: String,
    MyObject: Object,
    MyIndex: Number,
    Store: String,
    Store2: String
  },
  computed: {
    getSession() {
      return this.$store.getters["session/getState"]
    },
    IsClienteLoading() {
      return this.$store.getters["cliente/IsLoading"]
    },
    getIsOrcamentosLoading() {
      return this.$store.getters["orcamento/isLoading"]
    }
  },
  data: () => ({
    dialog: false
  }),
  watch: {
    dialog(val) {
      val || this.close()

      if (val && this.Store2 == "colaborador")
        this.MyObject.id_tipo_utilizador = 4

      /*if (
        val &&
        this.Store == "at" &&
        this.MyIndex > -1 &&
        this.MyObject.codigo
      ) {
        //this.$eventBus.$emit("loader", true)
        this.$store
          .dispatch("orcamento/get", {
            url: this.$serviceUrl,
            pwd: this.$servicePwd,
            id: this.MyObject.codigo
          })
          .then(() => {
            //this.$eventBus.$emit("loader", false)
          })
      }*/

      this.$forceUpdate()
    }
  },
  mounted() {},
  created() {
    this.$eventBus.$on("AddDialog", state => {
      this.dialog = state
    })

    this.$eventBus.$on("ResetForm", () => {
      this.ResetForm()
    })

    this.$eventBus.$on("SetMyObjectValues", data => {
      this.SetMyObjectValues(data)
    })
  },
  methods: {
    close() {
      this.dialog = false

      setTimeout(() => {
        this.$eventBus.$emit("loader", false)
        this.$eventBus.$emit("ClearDialog")
        this.$eventBus.$emit("ClearImage")
        this.$store.dispatch("orcamento/get", null)
        this.$refs.form.reset()
        this.CLONE = {}
      }, 110)
    },

    ResetForm() {
      this.$refs.form.reset()
      this.$forceUpdate()
    },

    SetMyObjectValues(data) {
      for (const key of Object.keys(data)) {
        this.MyObject[key] = data[key]
      }

      this.$forceUpdate()
    },

    AutoCompleteValues(store) {
      if (this.Store == "at" && store == "colaborador")
        return this.$store.getters["utilizador/getColaboradores"].map(el => {
          return el
        })
      else
        return this.$store.getters[store + "/getAll"].map(el => {
          return el
        })
    },

    changeAuto(store) {
      if (store == "cliente") {
        if (
          typeof this.MyObject.codigo != "undefined" &&
          this.MyObject.codigo > 0 &&
          typeof this.$store.getters["cliente/getCliente"](
            this.MyObject.codigo
          ) != "undefined"
        ) {
          var obj = {
            ...this.$store.getters["cliente/getCliente"](this.MyObject.codigo)
          }

          this.$store.dispatch("orcamento/get", {
            url: this.$serviceUrl,
            pwd: this.$servicePwd,
            id: this.MyObject.codigo
          })

          this.MyObject.nome = obj.clientenome || ""
          this.MyObject.telefone = obj.clientetelefone || ""
          this.MyObject.telemovel = obj.clientetelemovel || ""
          this.MyObject.email = obj.clienteemail || ""
          this.MyObject.localidade = obj.clientelocalidade || ""
          this.MyObject.cp = obj.clientecodigopostal || ""
          this.MyObject.morada = obj.clientemorada || ""
          this.MyObject.obs = obj.obs || ""
          this.MyObject.orcamento = ""
        }
      }
    },

    clearAuto(store) {
      if (
        store == "cliente" &&
        (this.Store == "at" || this.Store == "obra") &&
        this.MyIndex > -1
      ) {
        let a = this.MyObject.codigo
        setTimeout(() => {
          this.MyObject.codigo = a
        }, 0)
      } else if (
        store == "cliente" &&
        (this.Store == "at" || this.Store == "obra") &&
        this.MyIndex == -1
      ) {
        this.$refs.form.reset()
      } else if (store == "prioridade") {
        setTimeout(() => {
          this.MyObject.id_prioridade = null
        }, 0)
      } else if (store == "orcamento") {
        setTimeout(() => {
          this.MyObject.orcamento = null
        }, 0)
      } else if (store == "colaborador") {
        setTimeout(() => {
          this.MyObject.id_utilizador = null
        }, 0)
      }
    }
  }
}
</script>
